#nonseamless {
    display: none;
}
#checkoutGrid {
    display: grid;
    grid-template-columns: auto auto;
    font-weight: bold;
    gap: 0.5rem;
}
.borderTop {
    border-top: 2px solid black;
}