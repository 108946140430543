#pendingPaymentSection{
    padding: var(--padding);
    padding-top: 2rem;
}
#pendingPaymentSection > div{
    display: grid;
    grid-template-columns: 10% auto 15%;
    justify-items: center;
    align-items: center;
    border-bottom: 0.125rem solid #000f2c;
    padding: 0.5rem;
}
#pendingPaymentSection > div:first-child{
    font-weight: 600;
}
#pendingPaymentSection > div:last-child{
    border-bottom: none;
    grid-template-columns: auto;
}
#noPending{
    color: #000f2c;
    padding: 0.5rem;
    font-weight: bold;
}
#messageBoxChanged{
    display: none;
    position: absolute;
    grid-template-columns: auto auto !important;
    top: 50%;
    left: 50%;
    width: 23rem;
    height: 8rem;
    margin: -4rem 0 0 -11.5rem;
    z-index: 10;
    text-align: -webkit-center;
    border: 0.125rem solid #000f2c;
    text-align: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
    align-items: center;
    column-gap: 0.5rem;
    background-color: white;
}
#messageBoxChanged > div:first-child, #messageBoxChanged > div:nth-child(2){
    grid-column-start: 1;
    grid-column-end: 3;
}
#overley-back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 5;
    background: #000;
    filter: alpha(opacity=40);
    display: none;
}
@media only screen and (max-width:1420px) {
    #pendingPaymentSection > div{
        grid-template-columns: 10% auto 20%;
    }
}
@media only screen and (max-width:1000px) {
    #pendingPaymentSection > div{
        grid-template-columns: 10% auto 25%;
    }
}
@media only screen and (max-width:750px) {
    #messageBoxChanged{
        width: 16rem;
        margin: -4rem 0 0 -8rem;
    }
}
@media only screen and (max-width:500px) {
    #pendingPaymentSection > div{
        grid-template-columns: 7% auto 20%;
    }
    #messageBoxChanged{
        width: 14rem;
        margin: -4rem 0 0 -7rem;
    }
}
@media only screen and (max-width:350px) {
    #pendingPaymentSection > div{
        grid-template-columns: 10% auto 23%;
    }
}