.publicLogo {
    width: 10rem;
}
.publicNavigation {
    padding:0.5rem;
}
a {
    text-decoration: none;
    font-weight: bold;
    color: white;
}
#totalBlock {
    display: none;
    background-color: white;
    color: black;
    font-weight: bold;
    width: 10rem;
    padding: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    text-align: center;
}