:root {
    --padding : 0 5rem 0 5rem;
}
@media only screen and (max-width:1230px){
    :root {
        --padding : 0 3rem 0 3rem;
    }
}
@media only screen and (max-width:930px) {
    :root {
        --padding : 0 1rem 0 1rem;
    }
}