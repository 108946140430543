#rechargeForm{
    display: grid;
    grid-template-columns: 25% 25% 25% auto;
    padding-top: 1.5rem;
    justify-items: center;
    align-items: center;
    column-gap: 1rem;
    row-gap: 2rem;
}
.categoryNames {
    background-color: #000f2c;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-top: 1.5rem;
    padding: 0.2rem;
}
.category {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 1.5rem;
    padding: 0 1.5rem;
    row-gap: 1.5rem;
    column-gap: 1rem;
}
@media only screen and (max-width:1230px) {
    .category {
        grid-template-columns: auto auto auto auto;
    }
}
@media only screen and (max-width:940px) {
    .category {
        grid-template-columns: auto auto auto;
    }
    #rechargeForm{
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width:640px) {
    .category {
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width:600px) {
    #rechargeForm{
        grid-template-columns: auto;
    }
}