#selectedSubscriberForm {
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: auto;
    row-gap: 1rem;
}
.serachedResults {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
}