#datePickers {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    justify-items: center;
}
#ReportBtnContainer {
    grid-column-start: 1;
    grid-column-end: 3;
}
#totalBlocks {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 1rem;
    margin-top: 1rem;
}
#totalBlocks > div {
    background-color: #000f2c;
    color: white;
    text-align: center;
    padding: 0.5rem;
    border-radius: 0.3rem;
}
@media only screen and (max-width:800px) {
    #totalBlocks {
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width:500px) {
    #datePickers {
        grid-template-columns: auto;
        justify-items: unset;
    }
    #ReportBtnContainer {
        grid-column-start: 1;
        grid-column-end: 2;
    }
}