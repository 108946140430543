#packageSection {
    display: grid;
    padding: var(--padding);
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: auto;
    gap: 1rem;
}
#packageSection > div:first-child{
    font-weight: 600;
    text-align: center;
}
.packageGrid {
    display: grid;
    grid-template-columns: 20% auto;
}
.packageGrid > div:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    font-weight: 600;
    text-align: center;
    border: 0.123rem solid #000f2c;
    background-color: #000f2c;
    color: white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.packageGrid > div {
    padding: 0.5rem;
}
.innerDiv, .lastInnerDiv {
    border-left: 0.123rem solid #000f2c;
    border-bottom: 0.123rem solid #000f2c;
}
.lastInnerDiv.borderRadiusLeft {
    border-bottom-left-radius: 0.5rem;
}
.lastInnerDiv.borderRadiusRight {
    border-bottom-right-radius: 0.5rem;
}
.borderRight {
    border-right: 0.123rem solid #000f2c;
}
.wrapByWord {
    word-break: break-all;
}
#PageFooter {
    background-color: #000f2c;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.2rem 0;
}
@media only screen and (max-width:480px){
    .packageGrid {
        grid-template-columns: 30% auto;
    }
}