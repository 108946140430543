#failedRechargeSection{
    padding: var(--padding);
    padding-top: 2rem;
}
#failedRechargeSection > div{
    display: grid;
    grid-template-columns: 10% auto 15%;
    justify-items: center;
    align-items: center;
    border-bottom: 0.125rem solid #000f2c;
    padding: 0.5rem;
}
#failedRechargeSection > div:first-child{
    font-weight: 600;
}
#failedRechargeSection > div:last-child{
    border-bottom: none;
    grid-template-columns: auto;
}
@media only screen and (max-width:1420px) {
    #failedRechargeSection > div{
        grid-template-columns: 10% auto 20%;
    }
}
@media only screen and (max-width:1000px) {
    #failedRechargeSection > div{
        grid-template-columns: 10% auto 25%;
    }
}
@media only screen and (max-width:500px) {
    #failedRechargeSection > div{
        grid-template-columns: 7% auto 20%;
    }
}
@media only screen and (max-width:350px) {
    #failedRechargeSection > div{
        grid-template-columns: 10% auto 23%;
    }
}