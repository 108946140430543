#loginForm {
    display: grid;
    margin: auto;
    width: 30rem;
    justify-items: center;
    margin-top: 10rem;
    border: 0.2rem solid #000f2c;
    border-radius: 0.5rem;
    padding: 2rem;
    row-gap: 1rem;
}
#paymentReceivedContainer {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
}
@media only screen and (max-width:606px){
    #loginForm {
        width: 20rem;
    }
}
@media only screen and (max-width:422px){
    #loginForm {
        margin-top: 8rem;
        width: 15rem;
    }
}
@media only screen and (max-width:319px){
    #loginForm {
        width: 12rem;
    }
}